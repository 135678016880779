<template>
  <el-dialog
    title="投放策略设置"
    :visible="setStrategyShow"
    width="800px"
    @close="cancle"
    @open="openInit"
    :close-on-click-modal="false"
  >
    <div>
      <el-form
        :model="adSet"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="广告系列竞价策略："
          prop="bid_strategy"
          v-if="initData.ad"
        >
          <el-radio-group
            v-model="adSet.bid_strategy"
            @change="(v) => $emit('setBidStrategy', v)"
          >
            <el-radio
              :label="item.key"
              v-for="(item, i) in campaignBidStrategy"
              :key="i"
              >{{ item.desc }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="费用控制额："
          prop="bid_amount"
          required
          v-if="adSet.bid_strategy == 'COST_CAP' || adSet.bid_strategy == 'LOWEST_COST_WITH_BID_CAP'"
        >
          <el-input
            v-model="adSet.bid_amount"
            style="width: 200px"
            @blur="adSet.bid_amount = (adSet.bid_amount / 1).toFixed(2)"
            @input="(v) => $emit('onChageBidAmount', v)"
          >
            <el-button slot="append">{{ currencyName }}</el-button>
          </el-input>
          <span style="margin-left: 15px">单次转化量费用</span>
        </el-form-item>
        <el-form-item
          label="保底roas："
          prop="roas_average_floor"
          v-if="adSet.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS'"
        >
          <el-input
            v-model="adSet.roas_average_floor"
            style="width: 200px"
            type="number"
            @blur="adSet.roas_average_floor = (adSet.roas_average_floor / 1).toFixed(2)"
          >
            <!-- <el-button slot="append">{{currencyName}}</el-button> -->
          </el-input>
          <!-- <span style="margin-left:15px">单次转化量费用</span> -->
        </el-form-item>
        <el-form-item
          label="转化时间窗："
          prop="attribution_spec"
          v-if="!isFbPageAd"
        >
          <el-select v-model="adSet.attribution_spec">
            <el-option
              :label="times.desc"
              :value="times.key"
              v-for="times in attributionSpec"
              :key="times.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="计费方式："
          prop="billing_event"
        >
          <el-radio-group v-model="adSet.billing_event">
            <el-radio
              :label="types.key"
              v-for="types in targetTypeList"
              :key="types.key"
              >{{ types.desc }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <!-- <el-button @click="setStrategyShow = false">取 消</el-button> -->
      <el-button
        type="primary"
        @click="setStrategy"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    setStrategyShow: {
      type: Boolean,
      default: false,
    },
    targetTypeList: {
      type: Array,
      default: () => [],
    },
    initData: {
      type: Object,
      default: () => {},
    },
    adSeriesInfo: {
      type: Object,
      default: () => {},
    },
    adSetInfo: {
      type: Object,
      default: () => {},
    },
    isCustomSale: {
      type: Boolean,
      default: false,
    },
    attributionSpec: {
      type: Array,
      default: () => [],
    },
    isFbPageAd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let checkRoas = (rule, value, callback) => {
      if (!value) {
        callback(new Error('保底roas必填'));
      } else {
        if (value > 1000 || value < 0.01) {
          callback('最低roas有效范围【0.01-1000】');
        }
        callback();
      }
      callback();
    };
    let checkBid = (rule, value, callback) => {
      if (!value) {
        callback(new Error('出价必填'));
      } else {
        if (value < 1) {
          callback('出价最小值0.01');
        }
        callback();
      }
      callback();
    };
    return {
      rules: {
        bid_amount: [{ validator: checkBid, trigger: ['change', 'blur'] }],
        roas_average_floor: [{ validator: checkRoas, trigger: ['change', 'blur'] }],
        attribution_spec: [{ required: true, message: '请选择转化时间窗', trigger: ['change', 'blur'] }],
      },
      adSet: {},
    };
  },
  computed: {
    ...mapGetters('initData', ['getBidStrategyList', 'getAttributionSpec']),
    campaignBidStrategy() {
      return this.getBidStrategyList({
        is_cbo: this.adSet.is_cbo,
        objective: this.adSeriesInfo.objective,
        optimization_goal: this.adSet.optimization_goal,
        smart_promotion_type: this.adSeriesInfo.smart_promotion_type,
      });
    },
    // attributionSpec() {
    //   return this.getAttributionSpec(this.adSet.optimization_goal);
    // },
    currencyName() {
      return this.$store.getters.currentUiUnit.symbol;
    },
  },
  methods: {
    cancle() {
      this.$emit('update:setStrategyShow', false);
    },
    setStrategy() {
      this.$refs.ruleForm.validate((v) => {
        if (v) {
          this.$emit('setStrategy', this.adSet);
          this.cancle();
        }
      });
    },
    openInit() {
      this.adSet = JSON.parse(JSON.stringify(this.adSetInfo));
    },
  },
};
</script>
