<template>
    <div class="trackCreat">
        <div class="trackItem">
            <div class="title">预算及排期</div>
            <div class="formWrap">
                <el-form  label-width="140px" :model="yusuan">
                    <el-form-item label="广告系列预算优化：">
                        <el-switch v-model="adSet.is_cbo"></el-switch>
                        <p class="tips">启用广告系列预算优化(CBO)，系统会将广告系列预算根据广告组的实时表现分配给广告组，以获得更多转化。</p>
                    </el-form-item>
                    <el-form-item :label="`广告${adSet.is_cbo?'系列':'组总'}预算：`" v-if="!adSet.is_cbo">
                        <el-input v-model="adSet.budget">
                            <el-select v-model="adSet.budget_type" slot="prepend" placeholder="请选择" @change="selectBudgetType">
                                <el-option label="单日预算" value="daily"></el-option>
                                <el-option label="总预算" value="lifetime"></el-option>
                            </el-select>
                            <el-button slot="append">{{currencyName}}</el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="投放优化目标：" v-if="initData.ad">
                        <el-select v-model="adSet.optimization_goal" placeholder="请选择" @change="setTarget">
                            <el-option :label="item.desc" :value="item.key" v-for="(item,index) in initData.ad.AdSetOptimizationGoal" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="投放策略：">
                        <span class="celue">
                            <span>{{adSet.bid_strategy=='LOWEST_COST_WITHOUT_CAP'?'自动竞价':adSet.bid_strategy=='TARGET_COST'?'目标费用':''}}</span>
                            <el-tooltip class="item" effect="light" content="使用出价上限竞价策略，Facebook 会尽可能获得最多展示次数，且在每次竞拍中，竞价金额不会超过$2，按展示次数计费" placement="top-start">
                            <i class="el-icon-warning-outline"></i>
                            </el-tooltip>
                        </span>
                        <el-button type="text" @click="setStrategyShow = true">调整</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="trackItem">
            <div class="title">追踪及拆分</div>
            <div class="formWrap">
                <el-form  label-width="140px" :model="yusuan">
                    <el-form-item label="设置转化追踪：">
                        <el-checkbox label="Facebook Pixel像素代码" value="1" v-model="pixexCode" disabled></el-checkbox>
                        
                        <el-select v-model="adSeriesInfo.fbPixelId" placeholder="请选择" style="margin-left:30px" disabled>
                            <el-option :label="item.name" :value="item.pixel_id" v-for="(item,index) in pixelList" :key="index"></el-option>
                        </el-select>
                        <span style="margin:8px;color:#999">当前像素id：{{adSeriesInfo.fbPixelId}}</span>
                    </el-form-item>
                    
                    <el-form-item label="按受众拆分：" v-if="splitList && splitList.length>0">
                        <el-checkbox-group v-model="splitBy">
                            <el-checkbox  :value="item.id" v-for="(item) in splitList" :key="item.id" :label="item.id">{{item.name}}  {{item.num}}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="">
                       <el-button type="text" @click="complainConfig('webConfigShow')">网址追踪设置</el-button>
                       <el-button type="text" @click="complainConfig('nameConfigShow')">自动命名设置</el-button>
                       <el-button type="text" @click="complainConfig('adConfigShow')">广告结构设置</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="trackItem">
                <div class="title">投放及预览</div>
                <div class="formWrap">
                    <p style="margin-bottom:10px">根据你的预算排期和受众拆分，自动生成以下广告组和广告，你可以单独设置广告组排期和预算：</p>
                    <div class="series" v-if="!campaignId">
                        广告系列名称：
                        <span v-if="!editSeriesName">
                            <span>{{campaign_name}}</span> <i class="el-icon-edit" @click="editSeriesName = true"></i>
                        </span>
                        <span v-if="editSeriesName">
                            <el-input style="width:40%;margin-right:10px" v-model="campaign_name"></el-input>
                            <el-button type="text" @click="editSeriesName = false">确定</el-button>
                            <el-button type="text" @click="editSeriesName = false">取消</el-button>
                        </span>
                    </div>
                    <div>
                        <el-table  :data="tableData" style="width: 100%;margin-bottom: 20px;" border default-expand-all :tree-props="defaultProps" :span-method="objectSpanMethod" :indent="10" :show-summary="true" sum-text=" " :summary-method="getSummaries" row-key="index" @selection-change="selectAds">
                            <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                            <el-table-column  prop="date" label="广告组名称"  width="360">
                                 <template slot-scope="scope">
                                     <el-image v-if="scope.row.image_url" :src="scope.row.image_url" style="width:40px;height:40px"></el-image>
                                     <span>{{scope.row.name}}</span>

                                    <el-popover  placement="top"  width="260" v-model="scope.row.visible">
                                        <p style="margin-bottom:10px;"><el-input v-model="scope.row.name"></el-input></p>
                                        <div style="text-align: right; margin: 0">
                                            <el-button size="mini" type="text" @click="scope.row.visible=false">取消</el-button>
                                            <el-button type="primary" size="mini" @click="scope.row.visible=false">确定</el-button>
                                        </div>
                                        <el-button slot="reference" type="text"> <i class="el-icon-edit"></i></el-button>
                                    </el-popover>
                                    <!-- <span style="margin-left: 10px">{{ scope.row.date }}</span> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="广告组排期" width="320" >
                                <template slot-scope="scope" v-if="scope.row.dateType">
                                    <el-radio-group  v-model="scope.row.dateType" @change="selectDateType(scope.row.dateType,scope.$index)">
                                       <p style="margin-bottom:10px"  v-if="adSet.budget_type == 'daily'">
                                            <el-radio label="1">从今天开始长期投放</el-radio>
                                       </p>
                                       <p>
                                           <el-radio label="2">
                                            <div class="timeChoose"  @click="setDateType('2',scope.$index)">
                                                <el-date-picker
                                                    is-range
                                                    v-model="scope.row.dateGroup"
                                                    range-separator=""
                                                    value-format="yyyy-MM-dd HH:mm:ss"
                                                    type="daterange"
                                                    start-placeholder="开始时间"
                                                    end-placeholder="结束时间"
                                                    :default-time="[scope.row.start_date.split(' ')[1],scope.row.start_date.split(' ')[1]]"
                                                    placeholder="选择时间范围" style="width:220px">
                                                </el-date-picker>
                                                <span class="setTime" v-if="scope.row.dateGroup.length !== 2">设置开始和结束日期</span>
                                                <span class="setTime" v-else>{{scope.row.dateGroup[0]}}~{{scope.row.dateGroup[1]}}</span>
                                            </div>
                                        </el-radio>
                                       </p> 
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                            <el-table-column  prop="address" label="受众人群(万)" width="180">
                                <template slot-scope="scope" v-if="scope.row.users">
                                    <div>
                                        <el-popover placement="left" width="300" trigger="hover">
                                            <div class="previews" v-if="scope.row.targeting">
                                                <draw-gauge :users="scope.row.users"></draw-gauge>
                                                <div class="savePeople">
                                                    <div class="peopleItem"> <span>地区</span><div>{{scope.row.targeting[0].sentenceLines.geo_locations}}</div></div>
                                                    <div class="peopleItem"><span>语言</span><div>{{scope.row.targeting[0].sentenceLines.locales}}</div></div>
                                                    <div class="peopleItem"><span>兴趣</span><div>{{scope.row.targeting[0].sentenceLines.flexible_spec}}</div></div>
                                                    <div class="peopleItem"><span>性别</span><div>{{scope.row.targeting[0].sentenceLines.genders}}</div></div>
                                                    <div class="peopleItem"><span>年龄组</span><div>{{scope.row.targeting[0].sentenceLines.age_range}}</div></div>
                                                </div>
                                            </div>
                                            <span  slot="reference"><i class="el-icon-s-custom"></i>{{scope.row.users/10000}}</span>
                                        </el-popover>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column  prop="address" :label="'广告组预算 ('+currencyName+')'" width="300">
                                <template slot-scope="scope" v-if="scope.row.ads">
                                    <div>
                                        <div v-if="!scope.row.editYusuan">
                                            <div v-if="!adSet.is_cbo">
                                                <span>{{scope.row.budget}}</span>
                                                <el-button type="text" @click="editYusuan(scope.$index,scope.row)"><i class="el-icon-edit"></i></el-button>
                                                <p class="tips">平均分配</p>
                                            </div>
                                            <div v-if="adSet.is_cbo">
                                                <span>{{scope.row.daily_min?scope.row.daily_min:'最低不限'}}--{{scope.row.daily_max?scope.row.daily_max:'最高不限'}}</span>
                                                <el-button type="text" @click="editYusuan(scope.$index,scope.row)"><i class="el-icon-edit"></i></el-button>
                                                <p class="tips">使用广告系列预算</p>
                                            </div>
                                        </div>
                                        <div v-if="scope.row.editYusuan">
                                            <el-input v-model="scope.row.budget" @change="saveYusuan(scope.row.budget,scope.$index,scope.row)" @blur="saveYusuan(scope.row.budget,scope.$index,scope.row)" v-if="!adSet.is_cbo" >
                                                <el-button slot="append">{{currencyName}}</el-button>
                                            </el-input>
                                            <div v-if="adSet.is_cbo">
                                                <el-input v-model="scope.row.daily_min" style="width:40%"></el-input>
                                                    <span style="margin:0 2px">--</span>
                                                <el-input v-model="scope.row.daily_max" style="width:40%"></el-input>
                                                <el-button type="text" @click="saveYusuanGroup(scope.row.daily_min,scope.row.daily_max,scope.$index,scope.row)" style="margin-left:5px" size="mini">确定</el-button>
                                            </div>
                                        </div>

                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column  prop="address" :label="'竞价策略 ('+currencyName+')'" width="240">
                                <template slot-scope="scope" v-if="scope.row.targeting">
                                    <!-- 出价上限 -->
                                    <!-- <div v-if="scope.row.bid_strategy == 'LOWEST_COST_WITHOUT_CAP'">
                                         <div v-if="!scope.row.editShangxian">
                                            <div>
                                                <span>{{scope.row.bid_amount}}</span>
                                                <el-button type="text" @click="editShangxian(scope.$index,scope.row)"><i class="el-icon-edit"></i></el-button>
                                                <p class="tips">出价上限</p>
                                            </div> 
                                        </div>  
                                        <div v-if="scope.row.editShangxian">
                                            <el-input v-model="scope.row.bid_amount" @change="saveShangxian(scope.row.bid_amount,scope.$index,scope.row)" @blur="saveShangxian(scope.row.bid_amount,scope.$index,scope.row)">
                                                <el-button slot="append">{{currencyName}}</el-button>
                                            </el-input>
                                        </div>
                                    </div> -->
                                    <!-- 目标费用 -->
                                    <div  v-if="scope.row.bid_strategy != 'LOWEST_COST_WITHOUT_CAP'">
                                         <div v-if="!scope.row.editShangxian">
                                            <div>
                                                <span>{{scope.row.bid_amount}}</span>
                                                <el-button type="text" @click="editShangxian(scope.$index,scope.row)"><i class="el-icon-edit"></i></el-button>
                                                <p class="tips">出价上限</p>
                                            </div>
                                        </div>
                                        <div v-if="scope.row.editShangxian">
                                            <el-input v-model="scope.row.bid_amount" @change="saveShangxian(scope.row.bid_amount,scope.$index,scope.row)" @blur="saveShangxian(scope.row.bid_amount,scope.$index,scope.row)">
                                                <el-button slot="append">{{currencyName}}</el-button>
                                            </el-input>
                                        </div>
                                    </div>
                                    <!-- 自动竞价 -->
                                    <div v-else>
                                         <div>
                                            <span>{{scope.row.bid_amount}}</span>
                                            <p class="tips">自动竞价</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- <div class="block">
                            <el-pagination
                                layout="prev, pager, next"
                                :total="50">
                            </el-pagination>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>
        <el-dialog title="投放策略设置" :visible.sync="setStrategyShow" width="800px">
            <div>
                <el-form :model="adSet" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                    <el-form-item label="广告系列竞价策略：" prop="resource" v-if="initData.ad">
                        <el-radio-group v-model="adSet.bid_strategy">
                            <el-radio :label="item.key" v-for="(item,i) in initData.ad.CampaignBidStrategy" :key="i">{{item.desc}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="费用控制额：" prop="name" v-if="adSet.bid_strategy == 'TARGET_COST'">
                        <el-input v-model="adSet.bid_amount" style="width:200px;">
                            <el-button slot="append">{{currencyName}}</el-button>
                        </el-input>
                        <span style="margin-left:15px">单次转化量费用</span>
                    </el-form-item>
                    <el-form-item label="转化时间窗：" prop="region" v-if="initData.ad">
                        <el-select v-model="adSet.attribution_spec">
                            <el-option :label="times.desc" :value="times.key" v-for="(times) in initData.ad.AdSetAttributionSpec" :key="times.key"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="计费方式：" prop="resource">
                        <el-radio-group v-model="adSet.billing_event">
                            <el-radio :label="types.key" v-for="(types) in targetTypeList" :key="types.key">{{types.desc}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setStrategyShow = false">取 消</el-button>
                <el-button type="primary" @click="setStrategyShow = false">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 网页追踪设置 -->
        <web-config :webConfigShow="webConfigShow" @close="close" :dictsInit="dictsInit" @setWebsitUrl="setWebsitUrl" :fbAccountId="fbAccountId" :accountSet="accountSet"></web-config>
        <name-config :nameConfigShow="nameConfigShow" @close="close" :dictsInit="dictsInit" :fbAccountId="fbAccountId" :accountSet="accountSet" @setName="setName"></name-config>
        <ad-config :adConfigShow="adConfigShow" @close="close" :dictsInit="dictsInit" :fbAccountId="fbAccountId" :accountSet="accountSet" @setsplitType="setsplitType"></ad-config>
    </div>
</template>
<script>
import audiencePreviews from  './audiencePreviews'
import webConfig from  './webConfig'
import nameConfig from  './nameConfig'
import adConfig from  './adConfig'
import {getSetsByAccounts,getDicts,accountPixel,splitView,splitNum} from '@/api/creatAd.js'
import {adsetForCreate} from '@/api/adManagement.js'
import {fnCSTToYmd,fnMsToCST} from '@/utils/utils'
import DrawGauge from "./targeting/DrawGauge";
    export default {
        props:['accountId','initData','adSeriesInfo','adsInfo','audienceInfo','activeStep','pixelList','campaignInfo'],
        // props:{
        //     accountId:{
        //         type:Number,
        //         default:0
        //     },
        //     initData:{
        //         type:Object,
        //         default: () => {}
        //     },
        //     adSeriesInfo:{
        //         type:Object,
        //         default: () => {}
        //     }
        // },
        data() {
            return {
                yusuan:{},
                splitBy:[],
                pixexCode:true,
                editSeriesName:false,
                webConfigShow:false,
                nameConfigShow:false,
                adConfigShow:false,
                fbAccountId:'',
                dictsInit:{},
                targetList:[{name:'转化量',id:1},{name:'落地页浏览量',id:2},{name:'链接点击量',id:3},{name:'展示次数',id:4},{name:'单日独立覆盖人数',id:5}],
                tableData:[],
                visible: false,
                setStrategyShow:false,
                adSet:{
                    bid_strategy:'LOWEST_COST_WITHOUT_CAP',
                    budget:this.$store.getters.toFormatedMoney(4000).value,
                    budget_type:'daily',
                    billing_event:'IMPRESSIONS',
                    bid_amount:'',
                    optimization_goal:'OFFSITE_CONVERSIONS',
                    attribution_spec:'click_7_and_view_1',
                    is_cbo:false,
                    pacing_type: "standard"
                },
                rules:{},
                dataHelp:{},
                accountSet:{},
                nameObj:{},
                split_type:1,
                targetTypeList:[],
                splitParmas:{},
                splitList:[],
                split:[],
                split_num:1,
                campaign_name:'',
                defaultProps:{
                    children:'ads'
                },
                campaignId:null
            }
        },
        components:{
            DrawGauge,
            audiencePreviews,
            webConfig,
            nameConfig,
            adConfig
        },
        methods: {
            // 关闭
            close(v){
                this[v] = false
            },
            // 合并行
            objectSpanMethod({ row, column, rowIndex, columnIndex }) {
                if (!column.children) {
                    // return {
                    //     // rowspan: 2,
                    //     // colspan: 2
                    // };
                }
            },
            // 编辑表格广告组预算
            editYusuan(index,row){
                // console.log(index);
                row.editYusuan = true;
                // if(row.media_type){
                //     let indexs = row.index.split('-');
                //     this.$set(this.tableData[indexs[0]-1].ads,indexs[1],row)
                //     this.$set(this.tableData,indexs[0]-1,this.tableData[indexs[0]-1])
                // }else{
                //     this.$set(this.tableData,index,row)
                // }
                this.$set(this.tableData,index,row)
            },
            // 保存预算
            saveYusuan(val,index,row){
                // console.log(row);
                row.editYusuan = false;
                row.budget = val;
                // if(row.media_type){
                //     let indexs = row.index.split('-');
                //     this.$set(this.tableData[indexs[0]-1].ads,indexs[1],row)
                //     this.$set(this.tableData,indexs[0]-1,this.tableData[indexs[0]-1])
                // }else{
                //     this.$set(this.tableData,index,row)
                // }
                // this.$forceUpdate();
                this.$set(this.tableData,index,row)
            },
            // 保存预算上限下限
            saveYusuanGroup(val,val1,index,row){
                // console.log(row);
                row.editYusuan = false;
                row.daily_min = val;
                row.daily_max = val1;
                this.$set(this.tableData,index,row)
            },
            // 编辑表格广告组预算上限
            editShangxian(index,row){
                row.editShangxian = true;
                // if(row.media_type){
                //     let indexs = row.index.split('-');
                //     this.$set(this.tableData[indexs[0]-1].ads,indexs[1],row)
                //     this.$set(this.tableData,indexs[0]-1,this.tableData[indexs[0]-1])
                // }else{
                //     this.$set(this.tableData,index,row)
                // }
                this.$set(this.tableData,index,row)
                // this.$forceUpdate();
                // console.log(row);
            },
            // 保存预算上限
            saveShangxian(val,index,row){
                // console.log(row);
                row.editShangxian = false;
                row.bid_amount = val;
                // if(row.media_type){
                //     let indexs = row.index.split('-');
                //     this.$set(this.tableData[indexs[0]-1].ads,indexs[1],row)
                //     this.$set(this.tableData,indexs[0]-1,this.tableData[indexs[0]-1])
                // }else{
                //     this.$set(this.tableData,index,row)
                // }
                this.$set(this.tableData,index,row)
            //     this.$forceUpdate();
            },
            // 表格合计方法
            getSummaries(){
                // const { columns, data } = param;
                const sums = [];
                // sums[0] = '';
                sums[0] = '共生成个1广告组';
                // sums[1] = '总排期：2020/11/20 10:06-2020/11/27 10:06';
                return sums;
            },
            // 获取选中的表格数据
            selectAds(v){
                // console.log(v);
                let list  = [];
                v.map(item=>{

                })
            },
            // 点击设置
            complainConfig(v){
                this[v] = true
            },
            // 获取广告账户默认设置
            getAccountSet(v){
                // console.log('getAccountSet(v)',this.fbAccountId);
                getSetsByAccounts({accounts:[v]}).then(res=>{
                    // console.log('getSetsByAccounts',res);
                    this.accountSet = res.data.list[0];
                    // this.splitPreviews();
                })
            },
            // 获取命名规则字典项
            getDictsInit(){
                getDicts().then(res=>{
                    // console.log(res);
                    this.dictsInit = res.data;
                });
                
            },
            // 接收网页追踪参数设置
            setWebsitUrl(v){
                this.dataHelp.website_url = v;
                this.accountSet.gg_analytics = v;
            },
            // 接收命名参数
            setName(v){
                this.nameObj = v;
                this.accountSet = {...this.accountSet,...v};
            },
            // 设置拆分方式
            setsplitType(v){
                this.split_type = v;
                this.accountSet.split_type = v;
            },
            // 选择优化目标
            setTarget(v){
                let list = this.initData.ad.AdSetOptimizationGoal.filter(k=>k.key == v)
                this.targetTypeList = list[0].billingEvents;

                // console.log(this.targetTypeList);
            },
            formatedMoney(v) {
                return this.$store.getters.toFormatedMoney(v).formated;
                // return "$" + (parseFloat(v) / 100).toFixed(2);
            },
            // this.$store.getters.toBaseMoney
            selectDateType(v,index){
                // console.log(v);
                this.$set(this.tableData[index],'dateType',v)
                if(v == '1'){
                    // console.log(this.tableData[index].start_date);
                    this.tableData[index].end_date = null;
                    this.tableData[index].dateGroup = []
                }else{
                    // console.log(111);
                    this.setDateType(v,index)
                }
                // this.tableData[index].dateType = v
                // console.log(this.tableData[index]);
                this.$set(this.tableData,index,this.tableData[index])
                // this.$forceUpdate();
                // console.log(v);
            },
            selectBudgetType(v){
                
            },
            setDateType(v,index){
                // console.log(index);
                // this.tableData[index].dateType = v;
                this.$set(this.tableData[index],'dateType',v)
                // console.log(this.tableData[index]);
                this.tableData[index].end_date = fnCSTToYmd(new Date(new Date(this.tableData[index].start_date).getTime()+(24*3600*7*1000)));
                this.tableData[index].dateGroup = [this.tableData[index].start_date,this.tableData[index].end_date]
                this.$set(this.tableData,index,this.tableData[index])
            },
            // 预览广告拆分数据
            splitPreviews(){
                // console.log(this.adSet);
                    // console.log('this.audienceInfo',this.audienceInfo);
                    // console.log('this.adsInfo',this.adsInfo);
                    // console.log('this.adSeriesInfo',this.adSeriesInfo);
                    // console.log('this.accountSet',this.accountSet);
                    let audienceInfo = JSON.parse(JSON.stringify(this.audienceInfo))
                    audienceInfo.map(v=>{
                        if(v.geo_locations_region){
                            v.geo_locations_region = v.geo_locations_region.map(k=>k.key)
                        }
                        if(v.geo_locations_city){
                            v.geo_locations_city = v.geo_locations_city.map(k=>k.key)
                        }
                    })
                    let ad = {name:this.accountSet.ad_name}
                    let creatives = JSON.parse(JSON.stringify(this.adsInfo));
                    creatives.map(item=>{
                        if(item.mutiImgs){
                            item.mutiImgs = item.mutiImgs.filter(v=>v.source_id)
                        }
                    })
                    let adSet = JSON.parse(JSON.stringify(this.adSet));
                    let campaign = {};
                    adSet.sk = 'timeline'
                    adSet.name = this.accountSet.adset_name;
                    adSet.start_date = fnCSTToYmd(new Date());
                    adSet.targeting = audienceInfo;
                    campaign.name = this.accountSet.campaign_name;
                    campaign.pixel_event = this.adSeriesInfo.customEventType;
                    campaign.website_url = this.adSeriesInfo.siteUrl;
                    campaign.is_cbo = this.adSet.is_cbo;
                    campaign.status = 'ACTIVE';
                    campaign.pixel_id =  this.adSeriesInfo.fbPixelId;
                    campaign.objective =  this.adSeriesInfo.objective;
                    adSet.budget = this.$store.getters.toBaseMoney(adSet.budget)
                    let dataHelp = {website_url:this.accountSet.gg_analytics}
                    let params = {
                        ad:ad,
                        creatives:creatives,
                        adsets:adSet,
                        campaign:campaign,
                        dataHelp:dataHelp,
                        split_type:this.accountSet.split_type,
                        is_upload:true,
                        account_id:this.accountId,
                        split:this.split,
                        splitCount:this.split_num,
                        sale:this.adSeriesInfo.sale,
                        site:this.adSeriesInfo.site,
                    }
                    this.splitParmas = params;
                    splitView(this.splitParmas).then(res=>{
                        // console.log(res);
                        
                        let list = JSON.parse(JSON.stringify(res.data.adset));
                        this.campaign_name = res.data.campaign_name;
                        list.map((item,index)=>{
                            item.dateGroup = [item.start_date]
                            if(this.adSet.budget_type == 'daily'){
                                item.dateType = '1'
                            }else{
                                item.dateType = '2'
                                item.end_date = fnCSTToYmd(new Date(new Date(item.start_date).getTime()+(24*3600*7*1000)));
                                item.dateGroup[1] = item.end_date
                            }
                            item.index = index+1;
                            item.budget = item.budget?this.$store.getters.toFormatedMoney(item.budget).value:0
                            item.bid_amount = item.bid_amount?this.$store.getters.toFormatedMoney(item.bid_amount).value:0
                            item.daily_min = item.daily_min?this.$store.getters.toFormatedMoney(item.daily_min).value:0
                            item.daily_max = item.daily_max?this.$store.getters.toFormatedMoney(item.daily_max).value:0
                            if(item.ads.length){
                                item.ads.map((v,i)=>{
                                    v.index = item.index+'-'+i;
                                    item.parentIndex = item.index;
                                    this.$set(item.ads,i,v)
                                })
                            }
                            // this.$set(this.tableData,index,item)
                        })
                        this.tableData = [...list]
                        // console.log(this.tableData);
                    })
            }
        },
        computed:{
            currencyName(){
                return this.$store.getters.currentUiUnit.name;
            },
            showMoney(v){
                return this.$store.getters.toFormatedMoney(v).formated;
            }
        },
        mounted() {
            this.getDictsInit();
            if(this.initData.ad){
               this.setTarget('OFFSITE_CONVERSIONS')
            }else{
                this.targetTypeList = [{buyingTypes: [{desc: "默认", key: "AUCTION"}], desc: "展示量", key: "IMPRESSIONS"}]
            }

        },
        created() {
            this.getAccountSet(this.accountId)
            this.campaignId = this.$route.query.campaignId;

            adsetForCreate({campaignId:this.campaignId}).then(res=>{
                this.adSet.is_cbo = res.data.campaign.is_cbo;
                this.adSet.budget_type = res.data.adsets.budget_type.key;
                this.adSet.budget = this.$store.getters.toFormatedMoney(res.data.adsets.budget).value;
            })
        },
        watch: {
            "$store.getters.fbAccountId": function(v, ov) {
                // console.log('$store.getters.fbAccountId',v);
                this.fbAccountId = v;
                // this.getAccountSet(v)
            },
            adSet:{
                handler(){
                    if(this.activeStep == 4){
                        this.splitPreviews()
                    }
                },
                deep:true
            },
            audienceInfo:{
                handler(){
                    // console.log(this.audienceInfo);
                    // this.activeStep();
                },
                deep:true
            },
            activeStep(){
                if(this.activeStep == 4){
                    this.splitPreviews();
                    let audienceInfo = JSON.parse(JSON.stringify(this.audienceInfo))
                    audienceInfo.map(v=>{
                        if(v.geo_locations_region){
                            v.geo_locations_region = v.geo_locations_region.map(k=>k.key)
                        }
                        if(v.geo_locations_city){
                            v.geo_locations_city = v.geo_locations_city.map(k=>k.key)
                        }
                    })
                    splitNum(audienceInfo).then(res=>{
                        this.splitList = res.data;
                        this.splitBy=[];
                    })
                }
            },
            accountSet:{
                handler(){
                    // console.log('watch accountSet',this.accountSet)
                    if(this.activeStep == 4){
                        this.splitPreviews();
                    }
                },
                deep:true
            },
            splitBy:{
                handler(){
                    // console.log(this.splitBy);
                    let list = [];
                    let num = 1;
                    this.split = this.splitBy;
                    this.splitBy.map(item=>{
                        let n = this.splitList.filter(v=>v.id===item)
                        num = num*n[0].num
                    })
                    this.split_num = num;
                    this.splitPreviews();
                }
            },
            tableData:{
                handler(){
                    // this.$store.getters.toBaseMoney
                    let list = JSON.parse(JSON.stringify(this.tableData));
                    list.map(item=>{
                        item.budget = item.budget?this.$store.getters.toBaseMoney(item.budget):0
                        item.bid_amount = item.bid_amount?this.$store.getters.toBaseMoney(item.bid_amount):0
                        item.daily_min = item.daily_min?this.$store.getters.toBaseMoney(item.daily_min):0
                        item.daily_max = item.daily_max?this.$store.getters.toBaseMoney(item.daily_max):0
                    })
                    this.$emit('getTableData',{
                        campaign_name:this.campaign_name,
                        adset:list
                    });
                    this.$emit('getSplitParmas',this.splitParmas);
                },
                deep:true
            }
        },
    }
</script>
<style lang="scss">
.el-drawer.rtl{
      outline: none;
      *{
          outline: none;
      }
  }
  img{
      width: 100%;
      height: 100%;
  }
  .drawerCon{
    height: calc(100vh - 150px);
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 6px;
    // height: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: rgba(0,0,0,0.2);
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 0;
        background: rgba(0,0,0,0.1);
      }
  }
  .demo-drawer__footer{
      text-align: center;
      padding: 15px;
      border-top: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
    .trackCreat{
        .el-form{
            .el-input-group{
                width: 40%;
            }
        }

        .tips{
            font-size: 12px;
            color: #999;
            // margin-top: 5px;
        }
        .previews{
            flex: 200px 0 0;
            background: #fff;
            background-color: rgb(248, 251, 255);
        }
        .el-radio__label{
            width: 200px;
        }
        .timeChoose{
            position: relative;
            width: 260px;
            top: -15px;
            cursor: pointer;
            // overflow: hidden;
            // height: 40px;
            .el-range-editor{
                position: absolute;
                opacity: 0;
                z-index: 222;
                left: 20px;
                top: 0;
                height: 18px;
                cursor: pointer;
            }
            .setTime{
                position: absolute;
                left: 20px;
                top: 0;
                cursor: pointer;
                // height: 40px;
                // font-size: 14px;
                // color: #fff;
            }
        }
        .el-tooltip__popper{
            width: 300px;
        }
        .trackItem{
            margin-bottom: 20px;
            background: #fff;
            .title{
                padding: 20px;
                font-size: 16px;
                border-bottom: 1px solid #ddd;
            }
            .el-input-group__prepend{
                width: 120px;
            }
            .formWrap{
                padding: 20px;
                .series{
                    font-size: 14px;
                    margin: 15px 0;
                    color: #666;
                    i{
                        cursor: pointer;
                        margin-left: 15px;
                    }
                }
                .tips{
                    font-size: 12px;
                    color: #999;
                }
                .celue{
                    margin-right: 30px;
                    span{
                        margin-right: 5px;
                    }
                }
            }
        }
    }
</style>